/* Glossary.css */

.glossary-container {
    margin: 0 auto;
    display: flex;
    min-height: 100vh;
    max-width: 1200px;
}

.az-index {
    width: 50px;
    background: #f0f0f0;
    padding: 10px;
    position: sticky;
    top: 0;
    height: 100vh;
}

.az-index div {
    cursor: pointer;
    padding: 5px;
    text-align: center;
}

.az-index .selected {
    color: var(--color-background);
    background: var(--color-accent);
    font-weight: bold;
}

.terms-list {
    flex: 1;
    padding: 20px;
}

.terms-list h1 {
    margin-bottom: 20px;
}

.terms-list h2 {
    margin-top: 40px;
    border-bottom: 1px solid #ddd;
}

.terms-list ul {
    list-style: none;
    padding: 0;
}

.terms-list li {
    margin-bottom: 15px;
}

.origin-filter {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

}

.glossary-term {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.term {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.definition {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
}

.explanation {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
}

.see-also {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
}

.filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}