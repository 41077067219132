.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    height: 0;
    overflow: hidden;
    margin: var(--margin-xlarge);
    /* make a shadow around the video */
    box-shadow: 0 0 70px black;
    border-radius: 20px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

}





.technique-title {
    font-size: var(--font-size-medium);
    margin-bottom: 2rem;
}

.technique-item {
    text-align: center;
    padding-block: 4rem;


}

.technique-tags {
    font-family: var(--font-family);
    font-size: 1.4rem;
    color: rgb(87, 87, 87);
    margin-block: 5rem;
    text-align: center;
}

.technique-info h3 {
    margin: 0 0 5px 0;
    font-size: var(--font-size-large);
}

.technique-row.expanded .technique-info {
    background-size: cover;
}




.technique-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}


.technique-content {
    margin-block: 2rem;
}

.technique-thumbnail {
    width: 320px;
    height: 180px;
    margin-right: 15px;
}

.technique-thumbnail img,
.technique-thumbnail iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.learn-more-btn {
    text-align: center;
    padding: 10px 20px;
    background-color: var(--color-accent);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;

}

.learn-more-btn:hover {
    background-color: var(--color-accent-dark);
}