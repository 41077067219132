p {
    font-size: var(--font-size-base);
    font-family: var(--font-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-primary);
}

h3 {
    font-size: var(--font-size-small);
}

h4 {
    font-size: var(--font-size-base);
    padding-block: 1rem;
}

.header-title {
    font-family: var(--font-family);
    font-size: 4rem;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    color: var(--color-accent);
}




.learn-more-btn {
    font-size: large;
    font-family: var(--font-family);
}