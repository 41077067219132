/* TYPOGRAPHY */

p {
    padding: 2rem;
    margin: 0 auto;
    max-width: 800px;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    border: solid 1px var(--border-color);
}

a {
    color: var(--color-accent);
    text-decoration: none;
    font-size: 1.5rem;
}

h1 {
    margin: 5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 4rem;
    color: var(--color-accent);
}

h2 {
    margin-bottom: 2rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    color: var(--color-accent);
}


.performance {
    width: 100%;
    margin: 2rem auto;
    padding-bottom: 2rem;
    display: flex;
    font-size: 4rem;
    align-items: center;
    border: solid 1px var(--border-color);
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
}

.examples {
    margin: 2rem auto;
    padding-bottom: 2rem;
    display: flex;
    font-size: 4rem;
    align-items: center;
    justify-content: space-around;
    border: solid 1px var(--border-color);
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
}

.setup {
    padding: 2rem;
    display: flex;
    font-size: 4rem;
    align-items: center;
    border: solid 1px var(--border-color);


    cursor: pointer;
}

.min-max-height {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 200px;
}

.max-height {}

.min-height {}

.setup-img {
    margin: 1rem;
    height: 180px;

}

.end {
    margin: 1rem;
    height: 180px;
    cursor: pointer;
}

.step {
    display: flex;
    margin-inline: 1rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--border-color);
    cursor: pointer;
    transition: all 0.1s;
    padding: 1rem;



}

.page-content {
    max-width: 1200px;
    padding: 2rem 5rem;
    margin: 0 auto;

}




.current-step {
    /* transform: scale(1.1); */
    background-color: #bbb;
    border-radius: 10px;

    /* animation: pulse 0.5s infinite; */
    transition: background-color 0.2s;
}

/* create a pulsing animation */

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.arrow {
    height: 100px;


}


.arrow-up {
    /* rotate 180deg */
    transform: rotate(180deg);
}

.tie {
    width: 4rem;
    height: 4rem;
}

.attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.attachment-type {
    width: 1.5rem;
}

.attachment-position {
    font-size: 1.5rem;
}

.upline-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.upline-id {
    border-bottom: 1px solid black;
    font-size: 1.5rem;

}

.upline-height {
    font-size: 1.5rem
}





.legend {
    margin: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.legend section {
    margin-bottom: 4rem;
}

.legend h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.grid {
    margin: 2rem;
    display: flex;
    flex-direction: row;
    gap: 6rem;

    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
}




figure {
    text-align: center;
    margin: 2rem;
    width: 200px;
}

figure img {
    width: 8rem;
    height: 8rem;
    /* max-width: 10rem;
    max-height: 10rem; */



}

figure img.setup-icon {
    width: 20rem !important;
    height: 20rem !important;


}

figcaption {
    margin-top: 0.5rem;
    font-size: 2.2rem;
    text-transform: uppercase;
}

figcaption p {
    font-size: 1.3rem;
    color: #555;
    text-transform: initial;
}

.examplesimg {
    position: relative;
    width: 50vw;
    mix-blend-mode: darken;
}

.compound-step {
    margin: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    border-bottom: 2px solid;
}

section {
    margin-bottom: 200rem;
}

button {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    background-color: var(--color-background);
    cursor: pointer;
    transition: all 0.1s;
}

option {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    background-color: var(--color-background);
    cursor: pointer;
    transition: all 0.1s;
}

select {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    background-color: var(--color-background);
    cursor: pointer;
    transition: all 0.1s;
}

input {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    background-color: var(--color-background);
    cursor: pointer;
    transition: all 0.1s;
}

label {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    background-color: var(--color-background);
    cursor: pointer;
    transition: all 0.1s;
}


textarea {
    width: 100%;
    font-size: 1.5rem;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px var(--border-color);
    /* background-color: var(--color-background); */
    cursor: pointer;
    transition: all 0.1s;
}

#tie-height {
    /* transform: rotate(-90deg); */
}