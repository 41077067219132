/* Flex container for the dropdowns */
.filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-block: 2rem;
}

/* Search box styling */
.search-box {
    margin-bottom: 20px;
}

.search-box input {
    width: 100%;
    padding: 10px;
    font-size: var(--font-size-large);
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* style the serch box when cursor is in focus inside */

.search-box input:focus {
    outline: none;
    box-shadow: 0 0 5px var(--color-accent);
}

/* Dropdown style */
.dropdown-container select {
    padding: 8px;
    font-size: var(--font-size-base);
    border: 1px solid #ccc;
    border-radius: 4px;
    /* margin-bottom: 50px; */
}

.dropdown-container label {
    font-size: var(--font-size-small);
    text-transform: uppercase;
    margin-right: 10px;
}