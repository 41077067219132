.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;

    font-size: var(--font-size-large);

    & * {
        padding: 2rem
    }

    .img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        filter: opacity(0.9);

    }

    & a {
        font-weight: bold;
        text-decoration: none;

        color: var(--color-white);
        text-transform: uppercase;
    }

}

.disclaimer {
    max-width: 600px;
}