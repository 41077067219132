/* make filter container hidden on mobile phone devicess */

@media screen and (max-width: 768px) {


    .app-container {
        padding: 0;
        margin: 0;

    }

    .technique-item {}

    .filter-container {
        /* display: none; */
        flex-direction: column;
    }


    .technique-description {
        text-align: center;
    }

    .preview-image {

        border: 0;
        margin: 0;
        margin-top: 20px;
        padding: 0;
        border-radius: 0;
        width: 100%;
        aspect-ratio: 16/9;
    }




}