.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 60px 0 0 0;
}

nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 2rem;

    li {
        list-style-type: none;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }

}

.count {
    font-size: var(--font-size-base);
    text-align: center;
}

.preview-image {
    border: 0;
    margin: 0;
    margin-top: 20px;
    padding: 0;
    border-radius: 0;
    width: 100%;

    max-width: 500px;

}

.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: bold;
    background-color: var(--color-accent);
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 12px;

    cursor: pointer;
    display: block;
}

.scroll-to-top:hover {
    background-color: var(--color-accent-dark);
}