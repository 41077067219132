@import "./css/reset.css";
@import "./css/variables.css";
@import "./css/typography.css";
@import "./css/layout.css";
@import "./css/filters.css";
@import "./css/footer.css";
@import "./css/technique.css";
@import "./css/responsive.css";
@import "./css/glossary.css";
/* @import "./css/gpt.css"; */

body {
  background-color: var(--color-background);
  font-family: var(--font-family);
  font-size: var(font-size-base);

}

.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}