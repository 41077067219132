:root {

    --color-primary: #0a0a0a;
    --color-accent-dark: rgb(137, 30, 0);
    --color-accent: #a31910;
    --color-background: rgb(242, 240, 236);
    /* Make background gradient */


    --color-secondary: #333;
    --color-tertiary: #ccc;
    --color-quaternary: #e0e0e0;

    --font-family: 'Futura', sans-serif;
    --font-weight-bold: 700;
    --font-weight-normal: 400;
    --font-weight-light: 300;

    --font-size-base: 1.6rem;
    --font-size-small: 1.4rem;
    --font-size-medium: 1.8rem;
    --font-size-large: 2.4rem;


    --border-color: rgba(0, 0, 0, 0);

}